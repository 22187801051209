// import dependencies
import jQuery from 'jquery'
import './bootstrap'
import './fontawesome'
import './object-fit-polyfill'
import { addOnClickEventToSubmitButton } from './formbuilder-extention'

// load our scss entry point
import '../scss/app.scss'
import { dom } from "@fortawesome/fontawesome-svg-core"

// jQuery needs to be defined globally because it's used in October CMS
window.$ = window.jQuery = jQuery

// Extending formbuilder plugin with scroll up functionality
addOnClickEventToSubmitButton();

function smoothScroll(target, position) {
  if (!position) {
    position = $(target).offset().top
  }
  $('html, body').stop().animate({
    scrollTop: position
  }, {
    duration: 750,
    complete: function() {
      target.focus()
      if (!target.is(':focus')) { // checking if the target was not focused
        target.attr('tabindex', '-1') // adding tabindex for elements not focusable
        target.focus()
      }
    }
  })
}

function showAtPosition(element, options) {
  // merge options with defaults
  options = $.extend({
    offset: 400,
    visibleClass: 'show',
  }, options)

  var $window = $(window)

  // listen for scroll events
  $window.scroll(function () {
    // depending on current position, add / remove visible class
    if($window.scrollTop() >= options.offset) {
      element.addClass(options.visibleClass)
    } else {
      element.removeClass(options.visibleClass)
    }
  })
}

// smooth scrolling when clicking on links with smooth-scroll class
$('a.smooth-scroll').bind('click', function(event) {
  var $anchor = $(this)
  var target = $($anchor.attr('href'))
  smoothScroll(target)
})

// disable close on click of dropdowns with .keep-open class
$('.dropdown-menu.keep-open').on('click', function (e) {
  e.stopPropagation()
})

// close content-sections-mobile-nav on item click
$('.content-sections-mobile-nav ul li a').click(function() {
  // we need to subtract the open navigation from the scroll position.
  var $anchor = $(this)
  var openContentSectionsMobileNavHeight = $('.content-sections-mobile-nav').height()
  var closedContentSectionsMobileNavHeight = 56
  var openClosedDiff = openContentSectionsMobileNavHeight - closedContentSectionsMobileNavHeight
  var target = $($anchor.attr('href'))
  var position = $(target).offset().top - openClosedDiff
  smoothScroll(target, position)

  // close mobile navigation
  $('#contentSectionMobileNavCollapse').collapse('hide')
})

// prepare query value before submitting primo search
$('#primoSearch').submit(function() {
  $('#query').val($('#query_params').val() + $('#primoQueryRaw').val().replace(/[,]/g, " "));
});

// initialize scroll to top
showAtPosition($('.scroll-to-top'))

// replace icons after ajax update
$(window).on('ajaxUpdateComplete', function (e) {
  dom.i2svg()
})

// image caption
function attachCaptionToImage(imageElement, transferImageStylesToFigure = true) { 
	var img = $(imageElement);
	if (img.attr("alt")) {
		var altText = img.attr("alt").trim();
		if (altText) {
			// extract links in the form of [http://www.example.org/ link text]
			var urlRegexp = /\[ *((http:\/\/|https:\/\/)[^\] ]+)( +([^\]]*?) *)?\]/g;
			altText = altText.replace(urlRegexp, function(link) {
				var linkParts = /\[ *((http:\/\/|https:\/\/)[^\] ]+)( +([^\]]*?) *)?\]/.exec(link);
				if (linkParts && linkParts.length == 5) {
					var href = linkParts[1];
					var linkText = (linkParts[4])? linkParts[4] : linkParts[1];
					return "<a href='" + href + "' target='_blank'>" + linkText + "</a>";
				}
				else {
					return link;
				}
			});
			
			var parent = img.parent();
			if (parent.is("a")) {
				var insertPoint = parent;
			}
			else {
				var insertPoint = img;
			}
			var figure = $("<figure class='captioned' />")
			.insertAfter(insertPoint)
			.append(insertPoint)
			.append("<figcaption>" + altText + "</figcaption>");
			
			if (transferImageStylesToFigure) {
				figure.css(img.css(["margin-top", "margin-right", "margin-bottom", "margin-left", "width", "max-width", "vertical-align", "float", "display"]));
				img.addClass("with_captions");
			}
		}
	}
}

const containerFluidDivs = $("div.container-fluid");

const richeditorImages = containerFluidDivs.find("div.richeditor-content img");
richeditorImages.each(function(index, element ) { attachCaptionToImage(element, true); });

const blogPostImages = containerFluidDivs.find("div.post-detail div.content img").add(containerFluidDivs.find("div.post-preview div.featured-images img"));
blogPostImages.each(function(index, element ) { attachCaptionToImage(element, false); });


// js pop-up window for whatsapp
$('a.whatsapp').on('click',
	(function(width, height, offsetX, offsetY) {
		var jsPopUpDataStore = {};
		
		function openRequestedPopup(event) {
			if ($(window).width() > 3 * width && $(window).height() > 2 * height) {
				const windowName = (!event.delegateTarget.target || event.delegateTarget.target.startsWith("_"))? "js_popup" : event.delegateTarget.target;
				const url = event.delegateTarget.href;
				const pos = getPosition(event.currentTarget);
				var features = "menubar=no,status=no,width=" + width + ",height=" + height + ",left=" + (pos.x + offsetX) + ",top=" + (pos.y + offsetY);
				if (!jsPopUpDataStore[windowName] || !jsPopUpDataStore[windowName].windowObject || jsPopUpDataStore[windowName].windowObject.closed) {
					processPopUpWindow(url, windowName, features);
				}
				else if (jsPopUpDataStore[windowName].url != url) {
					processPopUpWindow(url, windowName, features).focus();
				}
				else {
					jsPopUpDataStore[windowName].windowObject.focus();
				};
				return false;
			}
			else {
				return true;
			}
		}
		
		function processPopUpWindow(url, windowName, features) {
		    if (Object.keys(jsPopUpDataStore).length == 0) {
		     	attachCleanUpHandler();
		    }
		    var windowsObject = window.open(url, windowName, features);
		    jsPopUpDataStore[windowName] = {
		    	windowObject: windowsObject,
		    	url: url
		    };
		    return windowsObject;
		}
		
		function getPosition(element) {
		    var rect = element.getBoundingClientRect();
		    return {
		        x: rect.x,
		        y: rect.y
		    };
		}
		
		function attachCleanUpHandler() {
			var hidden, visibilityChange;
			if (typeof document.hidden !== "undefined") {
				hidden = "hidden";
				visibilityChange = "visibilitychange";
			} else if (typeof document.msHidden !== "undefined") {
				hidden = "msHidden";
				visibilityChange = "msvisibilitychange";
			} else if (typeof document.webkitHidden !== "undefined") {
				hidden = "webkitHidden";
				visibilityChange = "webkitvisibilitychange";
			}
			
			if (typeof document.addEventListener === "undefined" || hidden === undefined) {
				console.log("No support for the Page Visibility API.");
				return;
			}
			
			function cleanUp() {
				for (let windowName in jsPopUpDataStore) {
			    	if (jsPopUpDataStore[windowName].windowObject && !jsPopUpDataStore[windowName].windowObject.closed) {
			    		jsPopUpDataStore[windowName].windowObject.close();
			    	}
				}
				jsPopUpDataStore = {};
			}
			
			function closePopUpWindows() {
			    if (document[hidden]) {
			    	cleanUp();
			    	document.removeEventListener(visibilityChange, closePopUpWindows);
			    	window.removeEventListener("beforeunload", cleanUp);
			    }
			}
			
			document.addEventListener(visibilityChange, closePopUpWindows);
			window.addEventListener("beforeunload", cleanUp);
		}
		
		return openRequestedPopup;
	
	})(400, 400, 150, 0)

);
